/**
 * service数据中心
 * @type {{list1: {text: string, right: [{title: string}, {title: string}, {title: string}], title: string}}[]}
 */
export const serviceData = [
    {
        title:'全链路用户运营',
        title2:'基于用户全生命周价值管理',
        text:'以社交媒体大数据洞察为核心，实现目标人群精准触达，提升社会化媒体传播获客效能' +
            '\n' +
            '从账号定位到内容策划运营，一站式自媒体矩阵托管服务\n' +
            '\n' +
            '依托数字化技术工具，帮助品牌构建私域护城河，实现持续留存和转化',
        list1:{
            title:'半私域代运营',
            list:[
                {
                    title:'双微托管代运营',
                    text:'一客一策，提供专业化解决方案：\n' +
                        '官方双微年度运营解决方案\n' +
                        '官方新媒体矩阵统筹解决方案\n' +
                        '创意内容策划及制作\n' +
                        '粉丝管理及口碑维护\n' +
                        '平台创意活动策划及执行\n' +
                        '……',
                },
                {
                    title:'电商平台代运营',
                    text:'一店一策，提供平台化模块运营：\n' +
                        '全平台电商营销策略支持\n' +
                        '电商平台门户及产品创意设计支持\n' +
                        '推广/促销/客服等模块化精细运营\n' +
                        '后台大数据深度技术支持\n' +
                        '……',
                },
                {
                    title:'视频平台代运营',
                    text:'一站一策，提供多维度内容创意：\n' +
                        '根据不同平台特征进行视频内容的创意运营\n' +
                        '针对抖音快手、B站、问答平台、视频门户等不同渠道进行定制化的视频内容创作及后台运维',
                },
                {
                    title:'直播策划与执行',
                    text:'一事一策，完善保障效果达成：\n' +
                        '技术支持，多路清晰流畅推送\n' +
                        '资源支持，红人带货增加热度\n' +
                        '策划支持，内容营销一体包装\n' +
                        '运营支持，完整流程强力执行\n' +
                        '团队支持，资深人员专业设备\n' +
                        '……',
                }
            ]
        },
        list2:{
            title:'私域用户运营',
            list:[
                {
                    title:'SCRM系统搭建',
                    text:'为客户提供全套的用户关系管理服务，助力品牌私域运营\n' +
                        '产品及服务包含：全渠道触点管理，用户数据整合，自动化营销及数据化指导\n' +
                        '支持私有化部署',
                },
                {
                    title:'APP/小程序\n' +
                        '开发及运营',
                    text:'为客户提供从“需求分析”到“产品功能设计”再到“开发交付、持续运营”的全链路解决方案\n' +
                        '包括：APP、小程序、前后台以及主流平台插件等',
                },
                {
                    title:'全员营销系统\n' +
                        '搭建及运营',
                    text:'提供以”员工、基盘用户“等为分销节点的营销工具\n' +
                        '通过：布置任务、分配物料、检查执行进度等，实现全员营销诉求',
                },
                {
                    title:'可视化用户数据\n' +
                        '管理系统搭建',
                    text:'为客户提供“轻量级接入方式”的数据管理系统，帮助企业提升数据资产运营能力\n' +
                        '产品功能包括：OneID、会员档案、客群筛选、客户画像、DMP包等\n' +
                        '支持私有化部署',
                }
            ]
        },
        list3:{
            title:'公域传播',
            list:[
                {
                    title:'品牌咨询与策略',
                    text:'专业技术团队及市场调研团队，集成丰富的大数据分析内核，为品牌提供具有实战意义的全域市场咨询\n' +
                        '公司具备拥有丰富行业经验的核心策略团队资源，为客户提供全案式品牌策略指导建议',
                },
                {
                    title:'整合数字化营销',
                    text:'为企业架构专业级的数字化整合营销平台，打通从企业到终端的全营销价值链，实现营销全功能的数字化、平台化。\n' +
                        '可通过平台实现信息导入、远程培训、资源互通、费效管控、CRM管理等高价值应用功能的全面整合',
                },
                {
                    title:'社会化媒体营销',
                    text:'昊明数科拥有丰富的新媒体传播资源，可实现新媒体渠道的精确价值扩散与口碑舆情的精准维护\n' +
                        '同时，与私域及半私域运营相结合，在公域社交平台进行高效社会化营销内容部署及运营，实现用户深度引流与评价',
                },
                {
                    title:'内容创意与制作',
                    text:'自有完整的内容创意团队，可实现传统广告内容公关化，公关内容社会化，以及爆款传播内容的深度策划与制作\n' +
                        '内容创意范畴涵盖文字、图片、音频、视频、动画、特效等多领域，可实现不同平台之间的内容整合创意策划与制作输出',
                }
            ]
        },
    },
    {
        title:'品牌声誉管理',
        title2:'一站式品牌声誉管理',
        text:'领先的监测工具和语义算法、海量的数据积累，帮助客户及时捕捉舆情，优化传播\n' +
            '\n' +
            '智能化全网互动口碑管理工具，高效执行口碑管理和SEO优化任务\n' +
            '\n' +
            '高效的媒体数字化管理工具，及时掌控敏感媒体信息，动态评估传播势能',
        list1:{
            title:'网络舆情监测',
            list:['全网及口碑舆情监测','7×24小时舆情预警','舆情管理应对策略','品牌传播监测及效果评估','竞品传播监测及对标分析']

        },
        list2:{
            title:'危机公关',
            list:['舆情危机应对策略','危机防控体系建立','危机管理培训']
        },
        list3:{
            title:'网络舆情管理',
            list:['口碑互动策划与执行','SEO搜索引擎优化','问答平台内容管理','媒体关系管理',]
        },
    }
]
import digitalize1 from '@/assets/Core/digitalize1.jpg'
import digitalize2 from '@/assets/Core/digitalize2.jpg'
import digitalize3 from '@/assets/Core/digitalize3.jpg'
import digitalize4 from '@/assets/Core/digitalize4.png'
import member1 from '@/assets/Core/member1.jpg'
import member2 from '@/assets/Core/member2.jpg'
import member3 from '@/assets/Core/member3.jpg'
import member4 from '@/assets/Core/member4.png'
import brand1 from '@/assets/Core/content-1.jpg'
import brand2 from '@/assets/Core/core-img1.png'
import brand3 from '@/assets/Core/return-top.jpg'
import brand4 from '@/assets/Core/content-2.png'


export const coreData = [
    {
        leftMenu:digitalize1,
        returnImg:digitalize3,
        list:[{src:digitalize2},{src:digitalize2}],
        textImg:digitalize4,
    },
    {
        leftMenu:member1,
        returnImg:member3,
        list:[{src:member2},{src:member2}],
        textImg:member4,
    },
    {
        leftMenu:brand2,
        returnImg:brand3,
        list:[{src:brand1},{src:brand1}],
        textImg:brand4,
    }
]
